import React from 'react';
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid, styled,
  TextField, CardContent,CardMedia,Card,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Rating from '@mui/material/Rating';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
  } from '@mui/material/AccordionSummary';
import {service} from './assets' 
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
// Customizable Area End
// Customizable Area Start
import Header from '../../landingpage/src/Header.web'
import CategoriessubcategoriesController, {
    Props,
  } from "./CategoriessubcategoriesController";
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ChevronRightSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
      {
        transform: 'rotate(90deg)',
      },
    [`& .${accordionSummaryClasses.content}`]: {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  
// Customizable Area End
export default class Categoriessubcategories extends CategoriessubcategoriesController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {categorySubcategoryData,categoryCards,searchCategory} = this.state
    return (
        <Box>
            <Header navigation={undefined} id={''} />

            <Box sx={{ display: "flex",width:"100%"}} >

                {/* Sidebar */}
                <Box sx={{boxShadow:"3px 0 5px rgba(0, 0, 0, 0.1)",maxWidth:"294px",minWidth: "294px", bgcolor: "#f8f8f8",padding:"24px"}}>
                    <Typography sx={{color:"#101828",fontWeight: 600,fontFamily:"Poppins",fontSize:"24px" }}>
                        Categories
                    </Typography>
                    {categorySubcategoryData.map((item:any,index:number) => (
                        <Accordion
                        key={index} 
                        expanded={this.state.expanded === `panel${index}`} 
                        onChange={this.handleChange(`panel${index}`)}
                        sx={{
                         "&.Mui-expanded": {
                             margin: 0, 
                         },
                         "&.MuiPaper-root-MuiAccordion-root":{
                             backgroundColor:"red",
                             border:"none"
                         },
                         backgroundColor: "#ffffff",border:"none"
                        }}
                        >
                            <AccordionSummary
                                expandIcon={this.state.expanded === index ? <ExpandMoreIcon /> : <ChevronRightSharpIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ flexDirection: "row-reverse", paddingLeft: 0,marginTop:"8px", backgroundColor: "#ffffff"}}
                            >
                                <Typography sx={{fontFamily:"Poppins",fontWeight:600,fontSize:"16px",color:"#1C1917"}}>{item?.attributes?.name}</Typography>
                            </AccordionSummary>
                            {item.attributes.sub_categories?.map((subCategory:any) => (
                                <AccordionDetails data-test-id="subCategory"  key={subCategory.id} onClick={() => this.handleClick(subCategory.id)}
                                sx={{cursor:"pointer",marginBottom:"19.5px",marginLeft:"8px",fontFamily:"Poppins",fontWeight:500,fontSize:"16.61px",color:"#707070",padding: "0px",borderTop:"none"}}>
                                    {subCategory.name}
                                </AccordionDetails>
                            ))}
                        </Accordion>
                    ))}
                </Box>

                {/* Main Content */}
                <Box sx={{flexGrow: 1 ,marginTop:"43px",padding:"0px 43px"}}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                        <Typography sx={{fontFamily:"Poppins",fontWeight:600,fontSize:"30px" , color:"#101828"}}>
                            Browse All Services
                        </Typography>
                        <TextField
                            data-test-id="search"
                            variant="outlined"
                            placeholder="Search"
                            value={searchCategory}
                            onChange={(e) => this.setState({searchCategory:e.target.value})}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); 
                                this.categoryCardsApi(); 
                              }
                            }}
                            sx={{ 
                                "& .MuiInputBase-input::placeholder": {
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    color: "#667085"
                                },
                                "& .MuiInputBase-input": {
                                    fontFamily: "Poppins",
                                },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    fontFamily: "Poppins",
                                    width: "320px", 
                                    height: "44px", 
                                    border: "1px solid #D6D3D1",
                                },
                            }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon sx={{ 
                                height:"20px",width:"20px",color:"#667085"
                             }} />
                          ),
                        }}
                        />
                    </Box>

                    {/* Service Cards */}
                    <Grid container spacing={2}>
                        {categoryCards && Array.isArray(categoryCards) && categoryCards.length > 0 ? (categoryCards?.map((service:any, index:any) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{boxShadow: 2,borderRadius:"16px" }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image = {(Array.isArray(service?.attributes?.listing_images) && service?.attributes?.listing_images.length>0 ) && service?.attributes?.listing_images[0]?.url}
                                        alt="image"
                                    />
                                    <CardContent>
                                        <Typography sx={{display:"flex",alignItems:"center",justifyContent:"center",width:"60.33px",borderRadius:"17px",fontFamily:"Poppins",fontWeight:700,fontSize:"10px",color:"#404145",backgroundColor:"#EFEFF0"}}>
                                            {service?.type}
                                        </Typography>
                                        <Typography sx={{marginTop:"13px",fontFamily:"Poppins",fontWeight:700,fontSize:"17px",color:"#404145"}}>
                                            {service?.attributes?.title}
                                        </Typography>
                                        <Box display="flex" flexDirection="row">
                          <Box style={{height:"12px",width:"13px",marginTop:"4px"}}>
                                    <Rating
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          height: "12px", width: "13px", color: "#6D8E55"
                                        },
                                      }}
                                      name="customized-10" defaultValue={2} max={1} />
                                  </Box>
                                  <Box marginRight="12px">
                                    <Typography sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"14px",color:"#6D8E55"}}>
                                      4.8
                                    </Typography>
                                  </Box>
                                  <Box marginRight="12px">
                                    <Typography sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"13px",color:"#B5B6BA"}}>
                                     (227)
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <QueryBuilderIcon style={{ height: "13px", width: "12px" }} /> <span style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: "14px", color: "#62646A" }}>{service?.attributes?.duration} hours</span>
                                  </Box>
                                </Box>
                                        <Typography sx={{marginTop:"5px",fontFamily:"Poppins",fontWeight:400,fontSize:"14px",color:"#222325"}}>
                                          by {service.attributes?.created_by}
                                        </Typography>
                                        <Typography sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"12px",color:"#95979D"}}>
                                         {service.attributes.description}
                                        </Typography>
                                        <Typography sx={{ marginTop:"15px",fontFamily:"Poppins",fontWeight:400,fontSize:"15px",color:"#404145" }}>
                                        {service.attributes.price ? `$${service?.attributes?.price}` : ""}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                              textTransform: "none", fontFamily: "Poppins",
                                              fontWeight: 600, fontSize: "14px", color: "#44041C", marginTop: "32.42px", borderRadius: "8px",
                                              border: "1px solid #44041C",
                                              "&:hover": {
                                                borderColor: "#44041C", 
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                            data-test-id="continueBtn"
                                            onClick={() => this.navigationTwo(service.id)}
                                        >
                                            Continue
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))) : (
                        
                          <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ height: "100vh",marginInline: "auto",
                            marginTop: "80px" }}
                        >
                          <Box mb={2}>
                            <img src={service} alt="No Service" style={{ maxHeight: "200px" }} />
                          </Box>
                          <Typography variant="h6" sx={{ fontFamily: "Poppins", fontWeight: 700 ,fontSize:"22px",color:"#4A4A4A"}}>
                           No services yet
                          </Typography>
                          <Typography sx={{ textAlign: "center", fontWeight:400,fontFamily: "Poppins", fontSize: "18px", color: "#4A4A4A", marginTop: 2 }}>
                            Please come back later, Lorem Ipsum
                          </Typography>
                          <Typography sx={{ textAlign: "center", fontWeight:400,fontFamily: "Poppins", fontSize: "18px", color: "#4A4A4A" }}>
                            Domet Amet sillus Apet.
                          </Typography>
                        </Box>)}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
