import React from "react";
import LocalListingAdsController, { Props } from "./LocalListingAdsController";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid, Card,CardMedia,CardContent,
  TextField,InputLabel,Dialog,DialogTitle,IconButton,DialogContent,Select,MenuItem,DialogActions
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Rating from '@mui/material/Rating';
import Header from "../../landingpage/src/Header.web";
import {imageUpload} from './assets';
import AddIcon from '@mui/icons-material/Add';
// Customizable Area End

export default class LocalListingAds extends LocalListingAdsController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {createListingData} = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
        <Header navigation={undefined} id={''} />
      <Box sx={{ padding: {xs:"34px 22px 0px 20px",sm:"43px 26px 0px 39px",md:"43px 26px 0px 39px",lg:"43px 80px 0px 80px"} }}>
        <Box marginBottom="40px" display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Your Listings
        </Typography>
        <TextField label="Search" variant="outlined" fullWidth sx={{width:"320px",height:"44px",borderRadius:"8px"}} />
        </Box>
          <Grid container spacing={3} padding="10px 0px">
              {createListingData && <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card sx={{ boxShadow: 2, borderRadius: "16px" }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={(Array.isArray(createListingData?.attributes?.listing_images) && createListingData?.attributes?.listing_images.length > 0) && createListingData?.attributes?.listing_images[0]?.url}
                    alt="serviceImage"
                  />
                  <CardContent>
                    <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "60.33px", borderRadius: "17px", fontFamily: "Poppins", fontWeight: 700, fontSize: "10px", color: "#404145", backgroundColor: "#EFEFF0" }}>
                      {createListingData?.type}
                    </Typography>
                    <Typography sx={{ marginTop: "13px", fontFamily: "Poppins", fontWeight: 700, fontSize: "17px", color: "#404145" }}>
                      {createListingData?.attributes?.title}
                    </Typography>
                    <Box display="flex" flexDirection="row">
                      <Box style={{ height: "12px", width: "13px", marginTop: "4px" }}>
                        <Rating
                          sx={{
                            "& .MuiSvgIcon-root": {
                              height: "12px", width: "13px", color: "#6D8E55"
                            },
                          }}
                          name="customized-10" defaultValue={2} max={1} />
                      </Box>
                      <Box marginRight="12px">
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: "#6D8E55" }}>
                          4.8
                        </Typography>
                      </Box>
                      <Box marginRight="12px">
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "13px", color: "#B5B6BA" }}>
                          (227)
                        </Typography>
                      </Box>
                      <Box>
                        <QueryBuilderIcon style={{ height: "13px", width: "12px" }} /> <span style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: "14px", color: "#62646A" }}>{createListingData?.attributes?.duration} hours</span>
                      </Box>
                    </Box>
                    <Typography sx={{ marginTop: "5px", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: "#222325" }}>
                      by {createListingData.attributes?.created_by}
                    </Typography>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "12px", color: "#95979D" }}>
                      {createListingData?.attributes?.description}
                    </Typography>
                    <Typography sx={{ marginTop: "15px", fontFamily: "Poppins", fontWeight: 400, fontSize: "15px", color: "#404145" }}>
                      {createListingData.attributes?.price ? `$${createListingData?.attributes?.price}` : ""}
                    </Typography>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ textTransform: "none", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", color: "#44041C", marginTop: "32.42px", borderRadius: "8px", border: "1px solid #44041C",
                      "&:hover": {
                        backgroundColor: "#44041C", // Prevents default hover effect
                        color: "#FFFFFF", // Ensures text color stays the same
                      },
                      "&:focus": {
                        backgroundColor: "#44041C",
                      },
                      "&:active": {
                        backgroundColor: "#44041C",
                      },
                    }}
                    >
                      Edit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>}
           
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                border: "2px dashed #A8A29E",
                height: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: 2,flexDirection:"column",borderRadius:"16px"
              }}
              onClick={this.handleDialogueOpen}
            >
              <Typography variant="h6" fontWeight="bold">
                + Create a Listing
              </Typography>
              <Typography variant="body2">Click to add a new listing</Typography>
            </Box>
          </Grid>
          </Grid>

          <Dialog open={this.state.openDialogue} onClose={this.handleClose} sx={{borderRadius:"16px",width:"100%",display:"flex",textAlign:"center",
            "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",width:"100%"
          },
          "& .MuiPaper-root": {
            width: "600px", 
            maxWidth: "90%", 
            borderRadius: "12px", 
            padding: "20px", 
          },
          }}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{fontFamily:"Poppins",fontWeight:"700",fontSize:"24px",color:"#000000"}}>Add Listing</Typography>
          <IconButton onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        <InputLabel sx={styles.inputLabelText}>Title</InputLabel>
          <TextField
            name="title"
            fullWidth
            sx={{
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "#CBD5E1",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#CBD5E1", 
                },
              },
            }}
            value={this.state.title}
            onChange={this.handleTitleChange}
          />
              <InputLabel sx={styles.inputLabelText}>Description</InputLabel>
              <TextField
                name="description"
                fullWidth
                multiline
                rows={3}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#CBD5E1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#CBD5E1",
                    },
                  },
                }}
                value={this.state.description}
                onChange={this.handleDescriptionChange}
                helperText={`${this.state.description.length}/500`}
              />
          <InputLabel sx={styles.inputLabelText}>Category</InputLabel>
              <Select
                displayEmpty
                fullWidth
                name="category"
                sx={{
                  borderRadius: "8px",
                  "& .MuiSelect-select": {
                    color: "#475569",
                  },
                  "& fieldset": {
                    borderRadius: "8px",
                    color: "#CBD5E1",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#CBD5E1",
                    textAlignLast: "left",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#475569",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#475569",
                  },
                }}
                value={this.state.selectedCategory}
                onChange={this.handleCategoryChange}
                IconComponent={KeyboardArrowDownIcon}
              >
                {this.state?.categorySubData &&
                  this.state?.categorySubData?.map((item: any) => (
                    <MenuItem key={item.attributes.id} value={item.attributes.id}>
                      {item.attributes.name}
                    </MenuItem>
                  ))}
              </Select>

          <InputLabel sx={styles.inputLabelText}>Sub-category</InputLabel>
              <Select
                displayEmpty
                fullWidth
                name="subCategory"
                sx={{
                  borderRadius: "8px",
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                }}
                IconComponent={KeyboardArrowDownIcon}
                value={this.state.selectedSubCategory} // Ensure the selected subcategory is properly tracked
                onChange={this.handleSubCategoryChange} // Call the function to update the state
              >
                {this.state.subCategories.map((sub: any) => (
                  <MenuItem key={sub.id} value={sub.id}>
                    {sub.name}
                  </MenuItem>
                ))}
              </Select>


          <InputLabel sx={styles.inputLabelText}>Price</InputLabel>
          <TextField
            name="price"
            type="text"
            fullWidth
            sx={{
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "#CBD5E1",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#CBD5E1", 
                },
              },
            }}
            value={this.state.price}
            onChange={this.handlePriceChange}
          />
          <InputLabel sx={styles.inputLabelText}>Duration in hours</InputLabel>
          <TextField
            name="duration"
            fullWidth
            type="text"
            sx={{
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "#CBD5E1",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#CBD5E1", 
                },
              },
            }}
            value={this.state.duration}
            onChange={this.handleDurationChange}
          />
          <Box mt={2} 
          textAlign="start"
          >
            <Typography sx={styles.inputLabelText}>Availabilities</Typography>
            <Button sx={{color:"#44041C"}}>
              <AddIcon sx={{color:"#44041C"}} /> Add
            </Button>
            
          </Box>
          <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        {this.state.uploadedImage.map((image:any) => (
          <div key={image.id} style={{ position: "relative" }}>
            <img
              src={image.id}
              alt="Uploaded"
              style={{ width: "100px", height: "100px", borderRadius: "8px" }}
            />
            <button
              onClick={() => this.removeImage(image.id)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                background: "red",
                color: "white",
                border: "none",
                cursor: "pointer",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
              }}
            >
              ×
            </button>
          </div>
        ))}
      </div>
          <InputLabel sx={styles.inputLabelText}>Images</InputLabel>
          <Box
            data-test-id="uploadImages"
            p={2}
            borderRadius="14px"
            border="2px dashed #44041C"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            onClick={this.handleBoxClick}
            sx={{cursor:"pointer"}}
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDrop}
          >
            <img src={imageUpload} alt="imageUpload" style={{width:"32px",height:"32px"}}/>
          
          <Box display="flex" flexDirection="column">
          <Typography sx={{fontFamily:"Poppins",fontWeight:700,fontSize:"12px",color:"#475569"}}>
             Click to upload <span style={{fontFamily:"Poppins",fontWeight:400,fontSize:"12px",color:"#475569"}}>or drag and drop</span>
            </Typography>
            <Typography sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"12px",color:"#475569"}}>
              (png, jpg)
            </Typography>
            </Box>
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              data-test-id="handleImageUpload"
              onChange={this.handleImageUpload}
              id="upload-button"
              ref={this.fileInputRef}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} sx={{borderRadius:"8px",width:"91px",height:"44px",border:"1px solid #44041C",color:"#44041C",fontFamily:"Poppins",fontWeight:700,fontSize:"16px"}}>
            Cancel
          </Button>
          <Button data-test-id="saveBtn" onClick={this.createListingCards} variant="contained" sx={{borderRadius:"8px",width:"73px",height:"44px",backgroundColor:"#44041C",color:"#FFFFFF",fontFamily:"Poppins",fontWeight:700,fontSize:"16px",
                "&:hover": {
                  backgroundColor: "#44041C",
                  color: "#FFFFFF",
                },
                "&:focus": {
                  backgroundColor: "#44041C",
                },
                "&:active": {
                  backgroundColor: "#44041C",
                },
              }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
      </Box>
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  inputLabelText:{
    fontFamily:"Poppins !important",
    fontSize:14,
    color:"#334155",
    // marginBottom:"4px",
    fontWeight:"700",
    textAlign:"start",
    marginTop:"16px"
  },
  slide: {
    textAlign: "center",
    background: "#fff",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "0 auto",
  },

  layout: {
    paddingLeft: "9%",
  },
};
// Customizable Area End
