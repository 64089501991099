import React from 'react'
// Customizable Area Start
import { AppBar,TextField, Toolbar,InputAdornment, Typography, Button, Box } from '@mui/material';
import {cooksyImage} from '../../../blocks/email-account-login/src/assets'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import {message} from './assets'
import HeaderController, {
    Props,
  } from "./HeaderController";
export default class Header extends HeaderController {
    constructor(props: Props) {
      super(props);
    }
    showMenu = ['/', '/LandingPageWeb'].includes(window.location.pathname);
    navigationItem = ['/WebAppointments', '/WebCategoriessubcategories'].includes(window.location.pathname);
    listing = window.location.pathname === "/LocalListingAds";
    render() {
  return (
    <AppBar sx={{ ...styles.appBar, backgroundColor: "#ffffff" }}>
    <Toolbar sx={styles.toolBar}>
      <img style={styles.logo} src={cooksyImage} alt="logo" />
      {!this.showMenu && !this.navigationItem && !this.listing && <Box style={styles.btnBox}>
        <Button style={styles.homeBtn}>Home</Button>
        <Button style={styles.serviceBtn}>Services
          <KeyboardArrowDownIcon style={styles.dropDown} />
        </Button>
      </Box>}
      {this.navigationItem && 
      <Box display="flex" gap="15px" marginLeft="80px">
        <Button style={styles.serviceBtn} onClick={this.handleNavigateToLandingPage}>Home</Button>
        <Button style={styles.serviceBtn}>Services</Button>
        <Button style={styles.serviceBtn}>Orders</Button>
        <Button style={styles.serviceBtn}>Contact</Button>
        <Button style={styles.serviceBtn}>Help</Button>
        </Box>}
      {this.listing && 
      <Box display="flex" gap="15px" marginLeft="80px">
        <Button style={styles.serviceBtn}>Listing</Button>
        <Button style={styles.serviceBtn}>Contact</Button>
        <Button style={styles.serviceBtn}>Help</Button>
        </Box>}
        <Box style={styles.btnBox}>
          {this.navigationItem || this.listing  ? (
            <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection:"row",gap:"16px"
            }}
          >

            <TextField
              placeholder="What are you looking for?"
              variant="outlined"
              // value={this.state.searchValue}
              data-testid="searchValue"
              onChange={this.handleSearchValueChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: "12px",
                  height: "40px",
                  width:"335px",
                  padding: "0",
                  "& .MuiInputBase-input::placeholder": {
                    fontFamily: "Poppins",
                    color: "#95979D",
                    fontWeight: 400,
                    fontSize: "16px",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Poppins",
                  },
                },
              }}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        backgroundColor: "#44041C",
                        borderTopRightRadius:"8px",
                        borderBottomRightRadius:"8px",
                        cursor: "pointer",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <SearchIcon
                        sx={{
                          width: "18px",
                          height: "18px",
                          color: "white",
                        }}
                      />
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
              <Box>
              <img src={message} style={{width:"32px" , height:"32px"}} alt="message"/>
              </Box>
              <Box>
            <Avatar alt="Remy Sharp" src="https://picsum.photos/200" sx={{ width: "40px", height: "40px" }} />
             </Box>
          </Box>
          ) : (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>
            <Typography style={styles.sellerText}>
              Become a Seller
            </Typography>
         </Box>
         <Box>
          <Button variant="contained" style={styles.signBtn}
            onClick={this.handleNavigation}
          >
            Sign in
          </Button>
          </Box>
          </Box>
           )}
        </Box>
    </Toolbar>
  </AppBar>
  )
}
}
// Customizable Area End
// Customizable Area Start
const styles = {
  appBar:{
    height: "64px",
    position: "static",
    color: "#ffffff", 
    backgroundColor: "#ffffff",
    boxShadow: "none",
    borderBottom:"1px solid #D6D3D1"
  },
  logo:{
    height:"40px" ,
    width:"110.3px",
  },
  homeBtn:{
    marginRight:"48px",
    textTransform:"none" as "none",
    color:"#475569",
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:"16px"
  },
  serviceBtn:{
    textTransform:"none" as "none",
    color:"#475569",
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:"16px"
  },
  dropDown:{
    color:"#57534E",
    width:"30px",
    height:"26px"
  },
  sellerText:{
    padding:"20px 16px 20px 0px",
    color:"#44041C" , 
    fontFamily:"Poppins",
    fontSize:"15px",
    fontWeight:600
  },
  signBtn:{
    margin:"12px 0px 12px 16px",
    fontFamily:"Poppins",
    fontSize:"15px",
    fontWeight:600,
    textTransform:"none" as "none",
    width:"124.75px",
    height: "40px",
    borderRadius:"8px",
    backgroundColor:"#44041C",
    color:"#FFFFFF",
    boxShadow:"none"
  },
  btnBox:{
    display:"flex",
    alignItems:"center"
  },
  toolBar:{
    display:"flex",
    justifyContent:"space-between",
    padding:{lg:"0px 24px",md:"0px 24px",sm:"0px 20px",xs:"0px 20px"}
  }
}
// Customizable Area End