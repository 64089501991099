import React from "react";
// Customizable Area Start
import Slider from "react-slick";
import {Button, InputAdornment,Menu,MenuItem,TextField,Box,Card, CardMedia, CardContent,Typography, IconButton, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import SearchIcon from '@mui/icons-material/Search'
import {brownBackground} from './assets'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Rating from '@mui/material/Rating';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import LandingPageController, {
  Props,
} from "./LandingPageController";
import Header from './Header.web'
// Customizable Area End
// Customizable Area Start
const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "23px",
        transform: "translateY(-50%)",
        zIndex: 1,
        width:"24px",
        height:"24px"
      }}
    >
      <ArrowBackIosIcon sx={{
        width:"12px",
        height:"12px",color:"#57534E"
      }} />
    </IconButton>
  );
};

function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "23px",
        right: { xs: "5px", sm: "5px", md: "10px", lg: "10px" },
        transform: "translateY(-50%)",
        zIndex: 1,
        width:"24px",
        height:"24px"
      }}
    >
      <ArrowForwardIosIcon sx={{
        width:"12px",
        height:"12px",color:"#57534E"
      }} />
    </IconButton>
  );
}
// Customizable Area End
export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
// Customizable Area Start
// Customizable Area End
  render() {
    // Slider settings
    // Customizable Area Start
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const {serviceCardsData,posterData} = this.state;
    return (
      <>
        <Header navigation={undefined} id={""} />
        <Box sx={{ borderBottom: "1px solid #DADBDD",position: "relative",padding:{lg:"0px 80px",md:"0px 80px",sm:"0px 20px",xs:"0px 20px"}, height:"46px"}}>
        <Box sx={{
            padding: "0px", 
            ".slick-slide": {
              height: "auto !important",
            },
          }}>
            <Slider {...sliderSettings}>
              {this.state?.categoryData?.map((item:any, index:number) => (
                <>
                <Box
                  key={index}
                  sx={{
                    padding: "10px",
                    textAlign: "center",
                    backgroundColor: "#ffffff",
                    borderRadius: "4px",
                    height:"auto",
                    position:"relative",
                    cursor:"pointer"
                  }}
                >
                  <Typography onClick={(e) => this.handleSubCategory(index,e)} sx={{ cursor:"pointer",fontSize: "16px",color:"#57534E",fontFamily:"Poppins",fontWeight:400, }}>
                    {item?.attributes?.name}
                  </Typography>
                </Box>
                <Menu
                open={this.state.openMenu === index}
                onClose={this.handleClose}
                anchorEl={this.state.anchorEl} 
                // disableScrollLock 
                anchorOrigin={{
                  vertical: "bottom", 
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      top: "108px !important",
                      borderTop:"2px solid #44041C",
                      borderRadius:"0px !important",
                      cursor:"pointer !important"
                    },
                  },
                }}
                
              >
                {item?.attributes?.sub_categories?.map((subcate:any, subIndex:number) => (
                  <MenuItem key={subIndex} onClick={this.handleClose} sx={{fontFamily:"Poppins",cursor: "pointer" ,fontWeight:400,fontSize:"16.16px",color:"#44041C"}}>
                    {subcate.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
              ))}
            </Slider>
          </Box>
          </Box>
        <Box sx={{ marginTop: "83px",position: "relative",padding:{lg:"0px 80px",md:"0px 80px",sm:"0px 20px",xs:"0px 20px"} }}>
          {posterData && posterData?.map((item:any) => {
            return (
              <Box
              sx={{
                height: { xs: "215px",sm:"215px", md: "374px", lg: "374px" },
                width: "100%",
                display: "flex",
                position:"relative",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
              }}
            >

                <Box
                  component="img"
                  src={item?.attributes?.poster_images[0]?.url} 
                  alt="banner Image"
                  sx={{
                    height: { xs: "auto", md: "374px", lg: "374px" },
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "16px",
                    top: "83px",
                    
                  }}
                />
               
                {/* Center Content */}
                <Box
                  sx={{
                    position: "absolute",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: { xs: "93%", sm: "93%", md: "93%", lg: "70%" },
                    left: { xs: "0px", md: "30px", lg: "66px" },
                    right: { xs: "0px", md: "30px", lg: "66px" },
                    padding: {xs:"32px 0px",sm:"34px 0px",md:"21px 0px",lg:"15px 0px "},
                    zIndex: 99,
                    margin: "auto",
                    "@media (max-width: 375px) and (max-height: 667px)": {
                      padding: "25px 0px",
                    },
                    "@media (max-width: 430px) and (max-height: 932px)": {
                      padding: "12px 0px",
                    },
                    "@media (max-width: 360px) and (max-height: 740px)": {
                      padding: "10px 0px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: { lg: "48px", md: "38px", sm: "20px", xs: "20px" },
                      color: "#FFFFFF",
                      width: { lg: "100%", md: "100%", sm: "75%", xs: "75%" },
                      margin: "auto",
                      "@media (max-width: 375px) and (max-height: 667px)": {
                        width: "78%",
                      },
                    }}
                  >
                    {item.attributes.title.split(", ")[0]}, <br />
                    {item.attributes.title.split(", ")[1]}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: { xs: "14px", md: "20px" },
                      color: "#FFFFFF",
                      marginTop: "8px",
                    }}
                  >
                    {item?.attributes?.description}
                  </Typography>

                  {/* Search Bar */}
                  <Box
                    sx={{
                      marginTop: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: { lg: "100%", md: "75%", sm: "100%", xs: "100%" },
                      maxWidth: { lg: "577px", md: "577px", sm: "100%", xs: "100%" },
                      left: { lg: "100px", md: "189px", sm: "0px", xs: "0px" },
                      top: { xs: "227px", md: "180px", lg: "180px" },
                      borderRadius: "12px",
                    }}
                  >
                    <TextField
                      placeholder="What do you want to learn?"
                      variant="outlined"
                      value={this.state.searchValue}
                      data-test-id="searchValue"
                      onChange={(e) => this.setState({searchValue:e.target.value})}
                      sx={{
                        padding: "0",
                        width: { lg: "100%", md: "70%", sm: "70%", xs: "100%" },
                        maxWidth: { lg: "577px", md: "577px", sm: "100%", xs: "100%" },
                        height: "60px",
                        '& .MuiOutlinedInput-root': {
                          borderRadius: "12px",
                          height: "60px",
                          padding: "0 12px",
                          backgroundColor: "#ffffff",
                          '& fieldset': {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          '&:hover fieldset': {
                            borderColor: "black",
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: "black",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            fontFamily: "Poppins",
                            color: "#95979D",
                            fontWeight: 400,
                            fontSize: "16px",
                          },
                          "& .MuiInputBase-input": {
                            fontFamily: "Poppins",
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box
                             onClick={this.handleSearch}
                              sx={{
                                backgroundColor: "#44041C",
                                borderRadius: "8px",
                                cursor: "pointer",
                                width: "48px",
                                height: "48px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <SearchIcon
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  color: "white",
                                }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>

              </Box>
            )
          })}
          <Box display="flex" flexDirection="column" marginTop="75px">
            <Grid container spacing={3}>
              {serviceCardsData && serviceCardsData?.map((serviceData:any, index:number) => {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Card>
                      <CardMedia
                        component="img"
                        width="295px"
                        height="166.58px"
                        alt="green iguana"
                        image = {(Array.isArray(serviceData?.attributes?.listing_images) && serviceData?.attributes?.listing_images.length>0 ) && serviceData?.attributes?.listing_images[0]?.url}
                      />
                      <CardContent>
                        <Box sx={{ display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"#EFEFF0",height:"19px",width:"60.33px",borderRadius:"17px"}}>
                        <Typography  sx={{fontFamily:"Poppins",fontWeight:700,fontSize:"10px"}}>
                          {serviceData?.type}
                        </Typography>
                        </Box>
                        <Box>
                        <Typography sx={{ fontFamily:"Poppins",fontWeight:700,fontSize:"18px" }}>
                         {serviceData?.attributes.title}
                        </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row">
                          <Box style={{height:"12px",width:"13px",marginRight:"80px"}}>
                          <Rating 
                          sx={{
                            "& .MuiSvgIcon-root": {
                              height:"12px",width:"13px",color:"#6D8E55"
                            },
                          }}
                          name="customized-10" defaultValue={2} max={1}   />
                          </Box>
                          <Box>
                            <QueryBuilderIcon style={{height:"13px",width:"12px"}} /> <span style={{fontWeight:400,fontFamily:"Poppins",fontSize:"14px",color:"#62646A"}}>2.3 hours</span>
                          </Box>
                        </Box>
                        <Box>
                        <Typography sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"14px",color:"#222325"}}>{serviceData?.attributes.created_by}</Typography>
                        </Box>
                        <Box>
                          <Typography sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"12px",color:"#95979D"}}>
                          {serviceData?.attributes.description}
                          </Typography>
                        </Box>
                        <Box sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"15px",color:"#404145"}}>
                          $ 69
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
              
            </Grid>
            <Grid item xs={12}>
              <Box style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin:"60px 0px"
              }}>
              <Button style={{
                  alignItems: "center",
                  border: "1px solid #44041C",
                  height: "56px",
                  width: "181px",
                  textTransform:"none",
                  fontFamily:"Poppins",fontWeight:400,fontSize:"16px",color:"#44041C"
                }} onClick={this.handleViewAll} disabled={this.state?.loading}>
                  {this.state.loading ? "Loading..." : "View All Services"}
                </Button>
              </Box>
        </Grid>
        <img src={brownBackground} alt="brownBackground" style={{display:"none"}}/>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// Customizable Area End